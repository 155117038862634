import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import { formatPricing, formatTimeDDMMYYY } from "../../util/helper";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
export default function CartList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionModel1, setSelectionModel1] = useState();
  useEffect(() => {
    if (companyId > 0) {
      getAnaliticoCartsTotal();
    }
  }, [companyId]);

  const getAnaliticoCartsTotal = async () => {
    const res = await userRequest.post(
      `${companyId}/reportCarts/analiticoCarts`,
      { time: "inativos", stories: [] }
    );

    setData(res.data);
  };
  const onButtonDeleteClick = async (e, row) => {
    if (currentUser.isAdmin) {
      if (!e.target.checked) {
        Swal.fire({
          title: "Tem certeza que deseja apagar?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#EE8253",
          confirmButtonText: "Sim, apagar!",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await deleteCarts();
          } else {
            getAnaliticoCartsTotal();
          }
        });
      } else {
        await deleteCarts();
      }
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para inativar usuário!",
        "warning"
      );
    }
  };
  const deleteCarts = async () => {
    const res = await userRequest.post(`${companyId}/carts/olds`, {
      cartIds: selectionModel1,
    });

    if (res.data > 0) {
      getAnaliticoCartsTotal();
    } else {
      Swal.fire("Atenção!", "Erro ao inativar usuário!", "error");
    }
  };
  const columnsCart = [
    {
      field: "name",
      headerName: "Nome",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contato",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "quantity",
      headerName: "Qtde de produtos",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "total",
      headerName: "Total",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "updatedAt",
      headerName: "Data",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTimeDDMMYYY(value)}`;
      },
      type: "date",
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateRight">
            <br></br>
            <Button
              className="custom_add_top"
              variant="outlined"
              color="secondary"
              startIcon={<DeleteOutlineIcon />}
              onClick={onButtonDeleteClick}
              size="small"
            >
              Apagar carrinhos
            </Button>
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 900 }}>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                rowSelectionModel={selectionModel1}
                onRowSelectionModelChange={(itm) => {
                  setSelectionModel1(itm);
                }}
                getRowId={(row) => row.cartId}
                rows={data}
                disableSelectionOnClick
                columns={columnsCart}
                pagination
                autoPageSize
                checkboxSelection
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
