import "./itens.css";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateFileUrl } from "../../requestMethods";
import {
  formatPricing,
  formatTime24hourFormat,
  formatCpfOrCnpj,
} from "../../util/helper";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useCompany } from "../../components/context/CompanyContext";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
export default function Itens() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const orderId = location.pathname.split("/")[3];
  const [data, setData] = useState();
  const [st, setStatus] = useState();
  const { companyId } = useCompany();
  const nav = useNavigate();
  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    if (companyId > 0) {
      getOrders();
    }
  }, [companyId]);

  const getOrders = async () => {
    try {
      const res = await userRequest.get(`${companyId}/orders/${orderId}`);

      setData(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  function returnAddress(address) {
    let street, cep;
    if (address) {
      street = `${address.street},${address.numAddress}  - compl.:${address.complement}`;
    }
    return street;
  }

  function returnCep(address) {
    let street, cep;
    if (address) {
      cep = `${address.cep} - ${address.city} - ${address.state}`;
    }
    return cep;
  }
  return (
    <>
      <div style={{ padding: "10px" }}>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          startIcon={<PrintIcon />}
          onClick={() => reactToPrintFn()}
        >
          Imprimir
        </Button>
      </div>
      <div ref={contentRef}>
        <div className="list">
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "grid",
              margin: "10px",
              padding: "0px",
            }}
          >
            <h2 style={{ margin: "0px", padding: "0px" }}>
              Pedido:{data && data.orderRef}
            </h2>
          </div>
          <br></br>
          <div>
            <table>
              <tr>
                <td width="50%">
                  <h4
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Criado: {data && formatTime24hourFormat(data.createdAt)}
                  </h4>
                </td>
                <td width="50%">
                  <h4
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Cliente: {data && data.customer.name}
                  </h4>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <h4
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Pago: {data && formatTime24hourFormat(data.paymentAt)}
                  </h4>
                </td>
                <td width="50%">
                  <h4
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    CNPJ/CPF:{data && formatCpfOrCnpj(data.customer.cnpj_cpf)}
                  </h4>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <h4
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Quantidade itens:{data ? data.quantity : 0} pc.
                  </h4>
                </td>
                <td width="50%">
                  <h4
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    End:{returnAddress(data && data.customer.address)}
                  </h4>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <h4
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    Total Pedido:{data ? formatPricing(data.amount) : 0}.
                  </h4>
                </td>

                <td width="50%">
                  {" "}
                  <h4
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {returnCep(data && data.customer.address)}
                  </h4>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <table className="custon_table">
              {data
                ? data.products.map((product) => (
                    <tr className="bordered">
                      <td>
                        <img
                          className="custom-item-img"
                          src={generateFileUrl(product.img)}
                        />
                      </td>
                      <td style={{ minWidth: "200px" }}>
                        <h4 className="custom_product_title">
                          {product.title}
                        </h4>
                        <h5 className="custom_cart_complement">
                          Vlr. unitário: {formatPricing(product.price)}
                        </h5>
                        <h5 className="custom_cart_complement">
                          Número de pcs:{product.quantity}
                        </h5>
                        <h5 className="custom_cart_complement">
                          Ref.:{product.ref}
                        </h5>
                        <h4 className="custom_product_price">
                          Preço:
                          {formatPricing(product.price * product.quantity)}
                        </h4>
                      </td>
                      <td>
                        <table className="custom-minigrid">
                          <thead>
                            <tr>
                              <td>&nbsp;</td>

                              {product.sizes.map((size) => (
                                <td key={size.ref}>{size.label}</td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {product.colors.map((color, iColor) => (
                              <tr key={color.ref}>
                                <td bgcolor={color.rgb}></td>
                                {product.sizes.map((size, iSize) => (
                                  <td
                                    key={size.ref}
                                    className={
                                      !product.colorsSizesGrid[iColor][iSize]
                                        ? "custom-minigrid-no-value"
                                        : undefined
                                    }
                                  >
                                    {product.colorsSizesGrid[iColor][iSize] ===
                                      undefined ||
                                    product.colorsSizesGrid[iColor][iSize] ===
                                      null ? (
                                      <DoDisturbIcon />
                                    ) : (
                                      product.colorsSizesGrid[iColor][iSize] ||
                                      0
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))
                : false}
            </table>
          </div>
        </div>
      </div>
      <div className="divRodape" onClick={() => nav(-1)}>
        voltar
      </div>
    </>
  );
}
