import "../../global.css";
import "./cashbackList.css";
import dayjs from "dayjs";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid/locales";
import { useEffect } from "react";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import { DeleteOutline } from "@mui/icons-material";
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { formatPricing } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";
import Switch from "@mui/material/Switch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function CashbackList() {
  const [cashbacks, setCashbacks] = useState([{}]);
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [dateNow, setDateNow] = useState();
  const [cashback, setCashback] = useState();
  const [configRules, setConfigRules] = useState();
  const [cashbackCustomer, setCashbackCustomer] = useState([]);
  const [selectionModel1, setSelectionModel1] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(titleSetRef({ title: "Cashback" }));
    if (companyId > 0) {
      setDateNow(dayjs(new Date()).format("YYYY-MM-DD"));
      getCashback();
      getCompany();
      getCustomerCashback();
    }
  }, [companyId]);

  const getCompany = async () => {
    try {
      setIsLoading(true);
      const res = await userRequest.get(`${companyId}/company`);

      if (res.data && res.data.configRules) {
        setConfigRules(res.data.configRules);
        setCashback(res.data.configRules.cashback);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getCashback = async () => {
    try {
      const res = await userRequest.get(`${companyId}/cashback`);
      setCashbacks(res.data);
    } catch {
      console.log();
    }
  };
  const getCustomerCashback = async () => {
    try {
      const res = await userRequest.get(`${companyId}/cashback/toApproval`);
      setCashbackCustomer(res.data);
    } catch (error) {}
  };
  function validaCashback() {
    if (inputs.cashback === "" || inputs.cashback === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo % Desconto deve ser preenchido.",
        "warning"
      );
      return false;
    }

    let cb = cashbacks.find((item) => item.deleted === null);
    if (cb) {
      Swal.fire(
        "Atenção!",
        "Você pode ter apenas 1 cashback ativo.",
        "warning"
      );
      return false;
    }

    // if (inputs.minValue && inputs.maxValue) {
    //   if (parseFloat(inputs.minValue) >= parseFloat(inputs.maxValue)) {
    //     Swal.fire(
    //       "Atenção!",
    //       "O campo Valor Mínimo deve ser menor que o campo Valor Máximo.",
    //       "warning"
    //     );
    //     return false;
    //   }
    // }

    if (inputs.expiryDays === undefined || inputs.expiryDays <= 0) {
      Swal.fire(
        "Atenção!",
        "O campo Dias para vencer deve ser maior que 0. ",
        "warning"
      );
      return false;
    }
    return true;
  }
  const addCashback = async () => {
    if (validaCashback()) {
      const res = await userRequest.post(`${companyId}/cashback`, {
        expiryDays: inputs.expiryDays,
        createdBy: currentUser.userId,
        companyId: companyId,
        percentCashback: inputs.cashback,
        minValue: parseFloat(inputs.minValue),
        maxValue: parseFloat(inputs.maxValue),
      });
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Cashback criada com sucesso!", "success");
        getCashback();
      }
    }
  };
  const deleteCashback = async (cp) => {
    const res = await userRequest.put(`${companyId}/cashback/${cp}`, {
      deleted: dateNow,
      lastUserUpdate: currentUser.userId,
    });

    if (Object.keys(res.data).length > 0) {
      getCashback();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir cashback!", "error");
    }
  };
  const deleteCashbackCustomer = async (cp) => {
    const res = await userRequest.put(
      `${companyId}/cashback/cashbackCustomer/${cp}`,
      {
        deleted: dateNow,
        lastUserUpdate: currentUser.userId,
      }
    );

    if (Object.keys(res.data).length > 0) {
      getCustomerCashback();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir cashback cliente!", "error");
    }
  };
  const onButtonDeleteClick = async (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteCashback(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o cashback!",
        "warning"
      );
    }
  };

  const onButtonDeleteCustomerClick = async (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteCashbackCustomer(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o cashback!",
        "warning"
      );
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/company/`, {
        configRules: configRules,
      });
    } catch (e) {}
  };
  const handleChangeDisableCashback = async (e) => {
    if (!e.target.checked) {
      if (currentUser.isAdmin) {
        Swal.fire({
          title: "Tem certeza que deseja desabilitar o Cashback?",
          text: "Todos cashback serão desativados e você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#EE8253",
          confirmButtonText: "Sim, excluir!",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setCashback(e.target.checked);
            configRules.cashback = false;
            deleteCashback("all");
            await Update();
          }
        });
      } else {
        Swal.fire(
          "Atenção!",
          "Você não tem permissão para desabilitar o cashback!",
          "warning"
        );
      }
    } else {
      setCashback(e.target.checked);
      configRules.cashback = true;
      await Update();
    }
  };

  const onButtonApprovalClick = async (e) => {
    const res = await userRequest.post(`${companyId}/cashback/approval`, {
      cashbackIds: selectionModel1,
    });

    if (res.data > 0) {
      getCustomerCashback();
    } else {
      Swal.fire("Atenção!", "Erro ao aprovar cashback!", "error");
    }
  };
  const columns = [
    {
      field: "cashbackId",
      align: "left",
      headerName: "Código",
      width: 100,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "percentCashback",
      align: "center",
      headerAlign: "center",
      headerName: "% Cashback",
      width: 150,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "expiryDays",
      align: "center",
      headerAlign: "center",
      headerName: "Dias vencimento",
      width: 150,
      headerClassName: "dataGridMuiHeader",
    },
    // {
    //   field: "minValue",
    //   headerName: "Valor Mínimo",
    //   width: 120,
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "dataGridMuiHeader",
    //   valueFormatter: (value) => {
    //     if (value === null) {
    //       return "";
    //     }
    //     return `${formatPricing(value)}`;
    //   },
    // },
    {
      field: "maxValue",
      headerName: "% Máximo do pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      // valueFormatter: (value) => {
      //   if (value === null) {
      //     return "";
      //   }
      //   return `${formatPricing(value)}`;
      // },
    },
    {
      field: "createdBy",
      headerName: "Criado por",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "deleted",
      headerName: "Inativado",
      width: 150,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueFormatter: (value) => {
        if (value === undefined || value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (!params.row.deleted) {
          return (
            <IconButton
              onClick={(e) => onButtonDeleteClick(e, params.row.cashbackId)}
            >
              <DeleteOutline />
            </IconButton>
          );
        }
      },
    },
  ];
  const columnsCashback = [
    {
      field: "cashbackId",
      align: "left",
      headerName: "Código",
      width: 100,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contato",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "amount",
      headerName: "Total",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "expiryDate",
      headerName: "Data Vencimento",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },

      type: "date",
    },

    {
      field: "managedName",
      headerName: "Vendedor(a)",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (!params.row.deleted) {
          return (
            <IconButton
              onClick={(e) =>
                onButtonDeleteCustomerClick(e, params.row.cashbackId)
              }
            >
              <DeleteOutline />
            </IconButton>
          );
        }
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <Switch
              defaultChecked={cashback}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => handleChangeDisableCashback(e)}
            />{" "}
            Cashback {cashback ? "Habilitado" : "Desabilitado"}
          </div>
          <div className="div_custom_UpdateList">
            <div>
              <table style={{ borderSpacing: "0px" }}>
                <tr style={{ padding: "0px" }}>
                  <td>% Cashback</td>
                  <td>Dias para vencer</td>
                  {/* <td>Mínimo valor pedido</td> */}
                  <td>% Máximo do pedido</td>
                </tr>
                <tr style={{ padding: "0px" }}>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "150px" }}
                      type="number"
                      name="cashback"
                      onChange={handleChange}
                      maxLength="100"
                      disabled={!cashback}
                    />
                  </td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "150px" }}
                      type="number"
                      name="expiryDays"
                      onChange={handleChange}
                      maxLength="100"
                      disabled={!cashback}
                    />
                  </td>
                  {/* <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "150px" }}
                      type="number"
                      name="minValue"
                      onChange={handleChange}
                      maxLength="100"
                      disabled={!cashback}
                    />
                  </td> */}
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "150px" }}
                      type="number"
                      name="maxValue"
                      onChange={handleChange}
                      maxLength="100"
                      disabled={!cashback}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div style={{ padding: "0px" }}>
              <Button
                variant="outlined"
                color="secondary"
                className="custom_mui_action"
                startIcon={<SaveIcon />}
                onClick={addCashback}
                disabled={!cashback}
              >
                Adicionar Cashback
              </Button>
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 300 }}>
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.cashbackId + row.createdAt}
                  rows={cashbacks}
                  disableSelectionOnClick
                  columns={columns}
                  pagination
                  autoPageSize
                />
              </div>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Liberação Cashbacks</label>
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 300 }}>
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  rowSelectionModel={selectionModel1}
                  onRowSelectionModelChange={(itm) => {
                    setSelectionModel1(itm);
                  }}
                  getRowId={(row) => row.cashbackId}
                  rows={cashbackCustomer}
                  disableSelectionOnClick
                  columns={columnsCashback}
                  pagination
                  autoPageSize
                  checkboxSelection
                />
              </div>
            </div>
            <div>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={onButtonApprovalClick}
                size="small"
              >
                Aprovar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
